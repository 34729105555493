import _ from "lodash";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

function CustomizedIcon({
  color, backgroundColor, icon, size, title, description, padding, ...rest
}) {
  return (
    <Box width="fit-content" {...rest}>
      <Box
        display="flex"
        sx={{
          width: "fit-content",
          margin: { xs: "0 auto", sm: 0 },
        }}
      >
        <Box
          sx={{
            borderRadius: "50%",
            background: backgroundColor || `${color}20`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: { xs: _.isNil(padding) ? 1.5 : padding, sm: _.isNil(padding) ? 2 : padding },
          }}
        >
          <StyledIcon size={size} icon={icon} color={color} />
        </Box>
      </Box>
      {title && description && (
        <Box textAlign={{ xs: "center", sm: "start" }}>
          <Typography my={1} fontWeight={700}>
            {title}
          </Typography>
          <Typography variant="small">{description}</Typography>
        </Box>
      )}
    </Box>
  );
}

const StyledIcon = styled(FontAwesomeIcon)(({ theme, size }) => `
  ${theme.breakpoints.down("sm")} {
    width: ${size || 36}px !important;
    height: ${size || 36}px !important;
  }
  ${theme.breakpoints.up("sm")} {
    width: ${size || 48}px !important;
    height: ${size || 48}px !important;
  }
`);

export default CustomizedIcon;
